/* Ripple effect */

.container {
  margin: 5px;
  min-width: 100px;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.25);
  transition: ease 0.5s;
  color: #FFFFFF;
  overflow: clip;
  background-color:#4B9FE1;
  user-select: none;
}
.container:hover {
  box-shadow: 0 2px 4px 2px rgba(100, 100, 100, 1)
}

.ripple {
    background-position: center;
    transition: background 0.8s;
    
  }
  .ripple:hover {
    /* background: transparent radial-gradient(circle, transparent 1%, rgba(61, 78, 100, 0.25) 0%) center/15000%; */
    background: transparent radial-gradient(circle, transparent 1%, rgba(202, 238, 247,0.25) 0%) center/15000%;
    color: #000;
  }
  .ripple:active {
    background-color: #0F2557;
    background-size: 100%;
    transition: background 0s;
  }